<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.fromDate.val" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.toDate.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.notDelivered.val" > Not Delivered</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.lateDelivery.val" > Late Delivery</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="198" :false-value=null v-model="state.optionFields.ltlOnly.val" > LTL Only</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.hideRequiredDeliveries.val" > Hide Required Deliveries</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.updateStatus.val" :disabled="true"> Update Status (<i class='small'>Temporarily Disabled</i>)</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "Options",
        components: { DatePicker },
        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache,
                },
            }
        }
    }
</script>

<style scoped>

</style>