<template>
    <div>
        <h1>Deliveries</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from '@/store/Report.store.js';
    import Options from "./deliveries_opt";
    import Results from "./utils/Results";

    export default {
        name: "deliveries",
        components: {
            Options,
            Results
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    fromDate: {val: new Date().toLocaleDateString(), qsField: "[dates][sch_receive_ship_from]"},
                    toDate: {val: new Date().toLocaleDateString(), qsField: "[dates][sch_receive_ship_to]"},
                    cdiLocationID: {val: '0', qsField: '[numbers][cdi_location_id]'},
                    notDelivered: {val: null, qsField: "[not_del]"},
                    lateDelivery: {val: null, qsField: "[late_del]"},
                    ltlOnly: {val: null, qsField: "[numbers][t.trucking_options_id]"},
                    hideRequiredDeliveries: {val: null, qsField: "[no_req_delivery_date]"},
                    updateStatus: {val: null, qsField: "[status]"}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Deliveries']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>